import service from '@/utils/service'

export function getProductList(params) {
  return service({
    url: `/front-goods/goodsApi/goodsList`,
    params
  })
}

export function getProductShow(params) {
  return service({
    url: `/front-goods/goodsApi/goodsInfo`,
    params
  })
}

export function getSearch(data) {
  return service({
    url: `/search/v1/indexSearch/index`,
    method: 'post',
    data
  })
}

export function getSearchClick(data) {
  return service({
    url: `/search/v1/indexSearch/click`,
    method: 'post',
    data
  })
}

export function getOrderNav(params) {
  return service({
    url: `/front-goods/goodsApi/goodsCategory`,
    params
  })
}
