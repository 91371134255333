<template>
  <div class="warp-bg">
    <van-nav-bar
      title="搜索结果"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="search-title"><p>您目前搜索的关键字“<span>{{ keyWord }}</span>”，共找到<span>{{ total }}</span>条相关记录</p></div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多啦"
      @load="onLoad"
    >
      <div v-for="item in lists" :key="item.id" class="listHot">
        <div class="list-img">
          <p @click="handleOrderShow(item.body.spu_id,item.body.sku_id,item.id,item.requestId)"><img :src="item.body.images"></p>
        </div>
        <div class="list-txt">
          <h2 @click="handleOrderShow(item.body.spu_id,item.body.sku_id,item.id,item.requestId)">{{ item.body.properties_name }}</h2>
          <p>{{ item.body.spu.details_filter }}</p>
          <h5><span v-for="(tag,index) in item.body.spu.tags" :key="index">{{ tag }}</span></h5>
          <h4>
            <span class="rateing">{{ item.body.spu.ratings.toFixed(1) }}
              <van-rate v-model="item.body.spu.ratings" allow-half void-icon="star" readonly size="12" gutter="1" color="#FFA717" void-color="#D8D8D8" />
            </span>
            <span class="distance" @click="handleBusinessShow(item.body.business.uuid)">
              {{ item.body.business.business_name }}
            </span>
          </h4>
        </div>
      </div>
    </van-list>
    <tab-bar />
  </div>
</template>

<script>
import { List } from 'vant'
import { getSearch, getSearchClick } from '@/api/product.js'
import TabBar from '@/components/tabBar.vue'
export default {
  name: 'OrderList',
  components: {
    TabBar,
    [List.name]: List
  },
  data() {
    return {
      category: '',
      lists: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 5,
      categoryName: '',
      projectId: '',
      keyWord: '',
      total: '',
      requestId: ''
    }
  },
  created() {
    this.keyWord = this.$route.params && this.$route.params.keyWord
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    async handleOrderShow(spuId, skuId, documentId, requestId) {
      const data = {
        request_id: requestId,
        document_id: documentId
      }
      await getSearchClick(data)
      this.$router.push({ path: `/orderShow/${spuId}/${skuId}` })
    },
    handleBusinessShow(id) {
      this.$router.push({ path: `/businessShow/${id}` })
    },
    async onLoad() {
      const data = {
        query: this.keyWord,
        sid: 'goods_sku',
        page: this.page,
        size: this.limit
      }
      const result = await getSearch(data)
      this.total = result.data.meta.total
      const list = result.data.results.map(item => {
        item.requestId = result.data.meta.request_id
        return item
      })
      this.lists = [...this.lists, ...list]
      this.page++
      this.loading = false
      // 数据全部加载完成
      if (this.page > result.data.meta.last_page) {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.search-title{
  width :100%;
  padding :0.15rem;
  border-bottom : 1px @greyE solid;
  p{
    line-height :.35rem;
    color :@black3;
    font-size :.25rem;
    span{
      color :@red;
      padding :0 0.1rem;
    }
  }
}
.list-bg{ padding :0 0.15rem; }
/deep/ .listHot{
  width: 100%;
  height :2rem;
  .autoHeight();
  padding :0.2rem 4%;
  background: @white;
  margin-bottom: 0.2rem;
}
.list-img{ width :40%; position: relative; float: left;
  p img{
    width :100%;
    height :1.8rem;
    border-radius: 5px;
  }
}
.list-txt{
  width: 60%;
  min-height :1.8rem;
  position : relative;
  padding-left:0.2rem;
  float: left;
  img{ width: auto; height: 0.3rem;}
  h2{
    font-size :0.3rem;
    line-height : 0.5rem;
    .LimitWord();
    -webkit-line-clamp :1;
    color :@black3;
    a{ color :@black3}
  }
  p{
    font-size :0.2rem;
    line-height : 0.3rem;
    margin-top: 0.1rem;
    .LimitWord();
    -webkit-line-clamp :1;
    color :#999;
  }
  h4{ width: 100%; display: flex; position: absolute; bottom: 0; left: 0.2rem;
    .rateing{ width: 60%; font-size: 0.28rem;}
    .distance{ width: 40%; color :@grey6; font-size : 0.2rem; text-align: right;}
    span{ font-size: 0.3rem; line-height:  0.3rem; color: #FFA717; margin-right: 0.1rem; font-family: Arial;}
  }
  h5{ margin-top: 0.05rem;
      span{ font-size: 12px; line-height: 12px; border:1px rgba(111, 133, 255, 0.34) solid; border-radius: 0.4rem; padding: 0 0.2rem; color: rgba(111, 133, 255, 1); margin-right: 5px;}
    }
}
</style>
